<template>
<div>
    <div class="row">
        <div class="col-10">
            
            <div class="form-group">
                <label for="exampleFormControlInput1">Email address</label>
                <input type="email" v-model="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
            </div>
            <button type="submit" class="btn btn-primary" v-on:click="getId()">Submit</button>
            <div class="form-group">
                <p>{{ idEmail }}</p>
            </div>

            <div class="form-group">
                <label for="exampleFormControlInput1">New pass</label>
                <input type="email" v-model="npass" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
            </div>
            <div class="form-group">
                <p>{{ message }}</p>
            </div>
            <button type="submit" class="btn btn-primary" v-on:click="updatePass()">Submit</button>


        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

export default {
    name: 'vjobs',
    data() {
        return {
            email: '',
            idEmail: '',
            npass: '',
            message: '',
        }
    },
    methods: {
        getId() {
            const url = this.appDomainApi1 + "admin/auth/id-user";
            const params = {
                email: this.email
            };
            
            axios.post(url,params)
            .then(res => {
                console.log(res);
                this.idEmail = res.data;
            })
            .catch(err => {
                console.error(err); 
            })
        },
        updatePass() {
            const url = this.appDomainApi1 + "admin/auth/store";
            const params = {
                id: this.idEmail.data.id,
                password: this.npass
            };
            
            axios.post(url,params)
            .then(res => {
                console.log(res);
                this.message = res.data;
            })
            .catch(err => {
                console.error(err); 
            })
        },
    },
}
</script>
