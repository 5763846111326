<template>
  <div>

    <titleAdmin msg="Admin" routerAdmin="Admin" />
    <div v-if="navigation.navigationAdmin.length<1">
      <p>loading</p>
    </div>
    <div v-else v-for="nav in navigation.navigationAdmin" :key="nav.id">
      <router-link :to="nav.route">{{ nav.name }}</router-link>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import titleAdmin from '@/views/constants/titleAdmin.vue';

export default {
  name:"AdminIndex",
  components: {
    titleAdmin
  },
  async created() {
    await this.$store.dispatch('setStudent');
    await this.$store.dispatch('navigation/setNavigationAdmin');
    await this.$store.dispatch('setRoles');
    await this.isArray();
  },
  mounted() {
  },
  methods: {
    isArray() {
      console.log("this.navigationAdmin");
      console.log(this.navigation.navigationAdmin);
      console.log("loadingNavigationAdmin");
      console.log(this.navigation.loadingNavigationAdmin);
      // console.log(this.navigationAdmin.length);
      const isArray = Array.isArray(this.navigation.navigationAdmin);
      console.log(isArray);
      // if (isArray) {
      //   console.log("count")
      // } else {
      //   window.location.href = this.navigationAdmin;
      // }
    }
  },
  computed: {
    ...mapState([
      "navigation",
      "student",
    ]),
  },
}
</script>

<style>
.nav {
  padding: 30px;
}

.nav a {
  font-weight: bold;
  color: #2c3e50;
}

.nav a.router-link-exact-active {
  color: #42b983;
}
</style>
